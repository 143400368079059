@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_home {
    height: 67vh;

    @include breakpoint(large){
        height: 36vh;
    }

    @include breakpoint(small only){
        margin-bottom: 100px;
    }

    @include breakpoint(medium){
        overflow: hidden;
    }

    .content-container {
        height: 100%;
    }

    .content-container .grid-x {
        align-items: center;
        height: 100%;
    }

    .hero-slider-image {
        position: absolute;
        width: 100vw;

        picture {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -2;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        .splide__track {
            height: 70vh;

            @include breakpoint(large){
                height: 36vh;
            }
        }
    }

    .hero-slider {
        z-index: 3;
    }

    .progress .splide__arrows.custom-arrows,
    .progress .splide-indicator {
        z-index: 4;
    }

    .cta {
        margin-top: 48px;

        .button--primary {
            margin-right: 16px;
        }

        @include breakpoint(large) {
            .font-hero {
                width: 60%;
            }
        }

        @include breakpoint(small only) {
            .button {
                width: 100%;
            }

            .button--primary {
                margin-bottom: 16px;
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:53";